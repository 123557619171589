import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NavbarVisibilityProvider } from "./components/NavbarVisibilityContext.jsx";
// import LocomotiveScroll from 'locomotive-scroll';


import Header from "./components/Header.jsx";
import SelectSkill from "./pages/SelectSkill.jsx";
import PrivateRoute from "./components/PrivateRoute.jsx";
import SkillTest from "./pages/SkillTest.jsx";
import ComingSoon from "./pages/ComingSoon.jsx";
import Instruction from "./components/Instruction.jsx";
import InitialResult from "./components/InitialResult.jsx";
import EndTest from "./components/EndTest.jsx";
import TestOptions from "./components/TestOptions.jsx";
import FooterCom from "./components/Footer.jsx";
import { FooterVisibilityProvider } from "./components/FooterVisibilityContext.jsx";
import ScrollToTop from "./components/ScrollToTop.jsx";
import ForgotPassword from "./pages/userAuth/ForgotPassword.jsx";
import ResetPassword from "./pages/userAuth/ResetPassword.jsx";
import HomePage from "./pages/Home.jsx";
import Dashboard from "./pages/Dashboard.jsx";
import UserForm from "./components/UserForm.jsx";
import ShowCandidateProfile from "./components/recruiterDashboard/ShowCandidateProfile.jsx";
import CookieBanner from "./components/CookieBanner.jsx";
import RecruiterForm from "./components/recruiterDashboard/RecruiterForm.jsx";
import Signin from "./pages/userAuth/Signin/Signin.jsx";
import Signup from "./pages/userAuth/Signup/Signup.jsx";
import About from "./pages/About.jsx";
import ViewPlans from "./pages/ViewPlans.jsx";
import Blog from "./pages/Blog.jsx";
import ViewBlog from "./components/ViewBlog.jsx";
import ViewBlogRequest from "./components/ViewBlogRequests.jsx";
import CustomTest from "./components/recruiterDashboard/CustomTest.jsx";
import HowItWorks from "./pages/HowItWorks.jsx";
import TalkToExpert from "./pages/TalkToExpert.jsx";
import Page404 from "./pages/Page404.jsx";

function App() {

  // const locomotiveScroll = new LocomotiveScroll();

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        < CookieBanner />
        <NavbarVisibilityProvider>
          <FooterVisibilityProvider>
            <Header />
            <Routes>
              
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<About />} />
              <Route path="/signin" element={<Signin />} />
              <Route path='/signup' element={<Signup />} />
              <Route path='/selectSkill' element={<SelectSkill />} />
              <Route path="/comingSoon" element={<ComingSoon />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route path="/resetPassword" element={<ResetPassword />} />
              <Route path="/viewPlans" element={<ViewPlans />} />
              <Route path="/how-it-works" element={<HowItWorks />} />
              <Route path="/talk-to-expert" element={<TalkToExpert />} />

              <Route path="*" element={<Page404 />} />

              <Route path="/customTest" element={<CustomTest />} />
              <Route path="/blog" element={<Blog/>} />
              <Route path="/viewBlog/:blogSlug" element={<ViewBlog />} />


              <Route element={<PrivateRoute />}>
              <Route path='/view-blog-request' element={<ViewBlogRequest />} />
                <Route path='/skillTest' element={<SkillTest />} />
                <Route path="/testInstruction" element={<Instruction />} />
                <Route path="/initialResult" element={<InitialResult />} />
                <Route path="/endTest" element={<EndTest />} />
                <Route path="/testOptions" element={<TestOptions />} />
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path="/userForm" element={<UserForm />} />
                <Route path="/filter-candidate/profile" element={<ShowCandidateProfile />} />
                <Route path="/recruiter-form" element={<RecruiterForm />} />
              </Route>


            </Routes>
            <FooterCom />
          </FooterVisibilityProvider>
        </NavbarVisibilityProvider>
      </BrowserRouter>
    </>
  );
}
export default App;