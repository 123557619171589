import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"
import DashSidebar from "../components/DashSidebar";
import DashboardCom from "../components/dashboard/DashboardCom.jsx";
import DashProfile from "../components/dashboard/DashProfile.jsx";
import DashUsers from "../components/dashboard/DashUsers.jsx";
import DashTests from "../components/dashboard/DashTests.jsx";
import DashConsult from "../components/dashboard/DashConsult.jsx";
import RecruiterDashCom from "../components/recruiterDashboard/RecruiterDashCom.jsx";
import RecruiterDashProfile from "../components/recruiterDashboard/RecruiterDashProfile.jsx";
import RecruiterDashSearch from "../components/recruiterDashboard/RecruiterDashSearch.jsx";
import CustomTest from "../components/recruiterDashboard/CustomTest.jsx";
import ViewedCandidates from "../components/recruiterDashboard/ViewedCandidates.jsx";
import DashQuestions from "../components/dashboard/DashQuestions.jsx";


export default function Dashboard() {

    const location = useLocation();
    const [tab, setTab] = useState();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const tabFromUrl = urlParams.get('tab');
        if (tabFromUrl) {
            setTab(tabFromUrl);
        }
    }, [location.search])
    return (
        <div className="max-w-[1300px] mx-auto">
            <div className='min-h-screen  flex flex-col md:flex-row'>
                <div className='md:w-56'>
                    {/* Sidebar */}
                    <DashSidebar />
                </div>
                <div className='mx-auto max-w-screen p-3 w-full'>
                    {/* Dashboard */}
                    {tab === 'dash' && <DashboardCom />}
                    {tab === 'recruiterDash' && <RecruiterDashCom />}

                    {/* Profile */}
                    {tab === 'profile' && <DashProfile />}
                    {tab === 'recruiterProfile' && <RecruiterDashProfile />}


                    {/* Custom Test */}
                    {tab === 'customTest' && <CustomTest />}

                    {/* Viewed Candidates */}
                    {tab === 'viewedCandidates' && <ViewedCandidates />}

                    {/* Search */}
                    {tab === 'search' && <RecruiterDashSearch />}

                    {/* Tests */}
                    {tab === 'tests' && <DashTests />}

                    {/* Consult */}
                    {tab === 'consult' && <DashConsult />}

                    {/* Dash Users */}
                    {tab === 'users' && <DashUsers />}
                    
                    {/* Dash QuestionSection */}
                    {tab === 'questionSection' && <DashQuestions />}

                </div>
            </div>
        </div>
    )
}