import { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet-async";

export default function About() {
  const sidesData = [
    {
      title: "1. Think You’re Ready? Test Yourself Now!",
      deatils:
        "Think you’re ready to take on the best? Test yourself now and uncover your true potential! With our skill assessments, you’ll get a precise snapshot of your strengths and areas to improve. It’s more than just a score—it’s your chance to challenge yourself, track your growth, and build the expertise needed to stand out. Take the first step towards mastering your skills today!",
      img: "./images/about/opt-1.webp",
    },
    {
      title: "2. Skills That Matter in Today’s Job Market",
      deatils:
        "Join the ranks of top performers! Test your skills, improve your score, and climb our leaderboard. Get noticed by peers and employers.",
      img: "./images/about/opt-2.webp",
    },
    {
      title: "3. Climb the Leaderboard and Get Recognized!",
      deatils:
        "Join the ranks of top performers! Test your skills, improve your score, and climb our leaderboard. Get noticed by peers and employers.",
      img: "./images/about/opt-3.webp",
    },
    {
      title: "4. Push Your Limits with Advanced Tests",
      deatils:
        "Already a pro? Take our advanced skill tests and challenge yourself to reach new heights. Test your expertise against the toughest standards and set yourself apart.",
      img: "./images/about/opt-4.webp",
    },
    {
      title: "5. Get Your Results Immediately!",
      deatils:
        "No waiting—get immediate results after completing your skill test. Understand your strengths and areas for improvement right away, so you can start working on your next step.",
      img: "./images/about/opt-5.webp",
    },
  ];

  const [selectedIndex, setSelectedIndex] = useState(0);

  // gsap.registerPlugin(ScrollTrigger);
  const gsapContainer = useRef();

  useEffect(() => {
    // Register GSAP ScrollTrigger
    gsap.registerPlugin(ScrollTrigger);

    // Select all elements with the 'about-us' class
    const aboutUsSections = document.querySelectorAll(".about-us");

    aboutUsSections.forEach((section) => {
      gsap.fromTo(
        section, // Target each section
        { opacity: 0, y: 30 }, // Initial state
        {
          opacity: 1,
          y: 0,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: section, // Trigger animation when the section enters the viewport
            start: "top 95%", // Animation starts when the top of the section is 80% into the viewport
            toggleActions: "play none none none", // Play animation on scroll
            once: true, // Run animation only once
          },
        }
      );
    });

    return () => {
      // Clean up ScrollTrigger instances on component unmount
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
        About Horg | Our Mission, Values & Story
        </title>
        <meta
          name="description"
          content="Discover Horg's mission, values, and impact on skill test industry with innovative solutions and a passion for excellence."
        />
      </Helmet>

      <div className=" flex flex-col items-center w-full overflow-y-auto top-0 -mt-20 scrollbar-none">
        <div className="w-full flex justify-center bg-gray-200">
          <div className="w-[1140px] md:mt-14">
            <h1 className="text-gray-900 text-[144px] font-bold mx-auto text-center my-14 about-us">
              About Us
            </h1>
            <div className="relative w-full p-9 pl-16  flex justify-center items-center gap-1 mx-auto about-us bg-white rounded-3xl">
              {/* Right Section */}
              <div className="relative w-full flex flex-row justify-end items-center">
                <img
                  // src="https://firebasestorage.googleapis.com/v0/b/benda-v1.firebasestorage.app/o/images%2Fabout-bg%20(1).avif?alt=media&token=427abbf4-91fe-482a-b84d-eb37fd663f5c"
                  src="./images/about-bg_11zon.webp"
                  alt="Person"
                  className="h-[500px] rounded-2xl object-cover"
                />

                {/* Left Section (Text) */}
                <div>
                  <div className="absolute z-20 left-10 top-1/2 transform -translate-y-1/2 -translate-x-14">
                    <h1 className="text-5xl md:text-7xl font-extrabold text-[#16286a] leading-tight">
                      OUR <br /> MISSION
                      <br /> IS TO <br />
                      SIMPLIFY
                      <br />
                      THE HIRING <br />
                      PROCESS
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-12 about-us">
              {/* Left Content */}
              <div className="flex flex-row justify-center gap-6 mb-16 mx-16">
                <p className="text-xl text-gray-800 first-letter:text-5xl first-letter:font-bold first-letter:text-gray-800 text-center">
                  At h-or-g.com, we’re revolutionizing the way businesses hire
                  and assess talent. Our mission is to simplify the hiring
                  process by combining skill tests with advanced recruitment
                  tools, helping companies find the right candidates quickly and
                  efficiently.
                </p>
                {/* <p className="mt-6 text-gray-700">
                                    We’re a team of passionate professionals dedicated to bridging the gap between employers and skilled talent. With a focus on innovation and user experience, we’ve built a platform that makes hiring smarter, faster, and more effective.
                                </p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between gap-6 h-auto w-full max-w-[1140px] items-center mt-28 about-us">
          {/* photo */}
          <div className="h-auto w-[565px]">
            <div
              className="w-[560px] h-[320px] bg-sky-300 rounded-lg shadow-lg"
              style={{
                backgroundImage: `url(${sidesData[selectedIndex].img})`,
                backgroundSize: "cover",
              }}
            ></div>
            <h3 className="text-gray-700 font-semibold border-gray-700 mt-4">
              {sidesData[selectedIndex].title}
            </h3>
            <p className="text-gray-600 text-[14px] mt-2">
              {sidesData[selectedIndex].deatils}
            </p>
          </div>
          {/* Text Section */}
          <div className="h-auto flex flex-col items-start">
            {sidesData.map((item, index) => (
              <div
                key={index}
                className={`p-5 cursor-pointer ${
                  selectedIndex === index ? "bg-sky-100 rounded-md" : ""
                }`}
                onClick={() => setSelectedIndex(index)}
              >
                <h3 className="text-gray-700 font-semibold border-gray-700">
                  {item.title}
                </h3>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-[#cdedff] w-full md:w-[1140px] h-[550px] mt-20 flex flex-col item-center justify-center mx-auto rounded-3xl shadow-md mb-5 about-us">
          <div className="w-full max-w-[1140px] mx-auto about-us">
            <div className="h-auto w-full flex flex-row justify-center gap-8 mt-4 ">
              <div
                className="bg-gray-200 h-44 w-80 rounded-lg shadow-md shadow-gray-700"
                style={{
                  backgroundImage: "url(./images/about/crd-bg-1.webp)",
                  backgroundSize: "cover",
                }}
              ></div>

              <div className="w-[450px] h-auto flex flex-col items-start justify-center">
                <h1 className="text-3xl text-gray-700 font-semibold text-start my-5">
                  Trusted by Our Clients
                </h1>
                <p className="text-sm text-gray-500 text-start">
                  We’re proud to support and empower our clients! Here’s a
                  glimpse at the amazing organizations and individuals who’ve
                  chosen us to help reach their goals. Thank you for being part
                  of our journey!
                </p>
              </div>
            </div>
            <div className="h-auto w-full flex flex-row justify-center items-center gap-4 mt-4">
              <div
                className="bg-gray-200 h-44 w-80 rounded-lg shadow-md shadow-gray-700"
                style={{
                  backgroundImage: "url(./images/about/crd-bg-2.webp)",
                  backgroundSize: "cover",
                }}
              ></div>

              <div
                className="bg-gray-200 h-52 w-96 rounded-lg shadow-md shadow-gray-700"
                style={{
                  backgroundImage: "url(./images/about/crd-bg-3.webp)",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
