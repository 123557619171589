import { Spinner } from "flowbite-react";
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";


export default function ViewBlogRequest() {

    const [pendingBlogs, setPendingBlogs] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {
        const fetchAllPendingBlogs = async () => {
            try {

                setLoading(true);
                const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/blogRequest/getAllBlogs`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: "include"
                });

                if (res.status === 200) {
                    const data = await res.json();
                    setPendingBlogs([...data.blogs]);
                    setLoading(false);
                } else if (res.status === 404) {
                    setPendingBlogs([]);
                    setLoading(false);
                }

            } catch (error) {
                console.log(error);
            }
        };

        fetchAllPendingBlogs();
    }, [])

    //---------------------------------- handle VIEW-BLOG -----------------------------------------
    const handleViewBlog = (blogData) => {
        navigate(`/viewBlog/${blogData.slug}`);
    }


    return (
        <>

            {loading ? (
                <div className="h-screen w-screen flex justify-center items-center">
                    <Spinner size="md" />
                </div>
            ) : (
                <div className="w-screen min-h-screen max-w-[1140px] mx-auto">
                    <div>
                        {pendingBlogs.length === 0 ? (
                            <div className=" h-full flex flex-col justify-center items-center mt-20">
                                <h1 className="text-2xl text-gray-700 font-semibold">No blog requests to show!</h1>
                                <Link to='/blog' className="text-gray-500 underline my-3">Go Back</Link>
                            </div>
                        ) : (
                            <div>
                                {pendingBlogs.map((b, index) => (
                                    <div className="group relative rounded-3xl p-6 shadow-lg bg-teal-100 cursor-pointer mt-4"
                                        style={{ backgroundImage: `url(${b.image})`, backgroundSize: 'cover' }}
                                        onClick={() => handleViewBlog(b)}>
                                        {/* Overlay for hover effect */}
                                        <div className="absolute inset-0 rounded-3xl bg-black bg-opacity-0 group-hover:bg-opacity-65 transition-opacity duration-300"
                                        ></div>

                                        {/* Hover text */}
                                        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                            <span className="text-white text-lg font-bold">View Blog</span>
                                        </div>

                                        <p className="inline-block -translate-x-10 -translate-y-9 px-4 py-2 text-xs font-josefinSans text-gray-100 bg-[#2f5ad1] border-4 border-[#f0f4e8] rounded-full">{b.category}</p>
                                        <h2 className="text-xl font-bold text-white font-josefinSans bg-black px-3 py-2 text-center bg-opacity-65 rounded-2xl">{b.title}</h2>
                                        {/* <p className="mt-2 text-sm text-gray-500 font-josefinSans">
                                    Lorem ipsum dolor sit amet consectetur adipisicing.
                                </p> */}

                                        {/* Zoom effect */}
                                        <div className="absolute inset-0 transform scale-100 group-hover:scale-105 transition-transform duration-300"></div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}

        </>
    )
}