import React, { useEffect } from 'react';
import { RxCross2 } from 'react-icons/rx';
import CodeMirror, { oneDark } from "@uiw/react-codemirror";

function ViewQuestion({ showModal, data }) {

    useEffect(() => {
        // Prevent background scroll when modal is open
        document.body.style.overflow = 'hidden';
        return () => {
            // Restore background scroll when modal is closed
            document.body.style.overflow = '';
        };
    }, []);

    return (
        <div className="fixed inset-0 bg-white bg-opacity-50 flex items-center justify-center mt-14">
            <div className="bg-white w-[70%] h-[80%] shadow-lg rounded-lg p-6 relative overflow-y-auto overflow-x-hidden scrollbar-none">
                <span className='flex justify-end'>
                    <RxCross2 className='text-gray-400 hover:text-gray-800 right-0 transform -translate-y-3 translate-x-3'
                        size={30}
                        onClick={() => showModal(false)}
                    />
                </span>
                <div className='w-full flex justify-between items-center my-4'>
                    <h3 className='text-xs px-1 py-[2px] bg-slate-800 text-white rounded'>Skill: <span className='uppercase font-semibold'>{data.language}</span></h3>
                    <div className='flex gap-2 justify-end  transform -translate-y-3 translate-x-3'>
                        <p className='px-2 py-1 rounded-lg bg-sky-600 text-white text-xs'>Sub: <span className='uppercase font-semibold'>{data.subject ? data.subject : data.language}</span></p>
                        <p className='px-2 py-1 rounded-lg bg-sky-600 text-white uppercase text-xs'>{data.type}</p>

                        {data.type === 'coding' && <p className={`px-2 py-1 rounded-lg ${data.level === 'easy' ? 'bg-green-500' :
                            data.level === 'medium' ? 'bg-blue-600' : 'bg-red-600'} text-white uppercase text-xs`}>{data.level}</p>}
                    </div>
                </div>

                {data.type === 'mcq' ? (
                    <div className='w-full mx-2 p-4 flex flex-col'>
                        <h1 className='font-semibold text-sm'><span className='font-bold'>Q) </span>{data.question}</h1>
                        <div className='w-full flex justify-center flex-wrap gap-3 my-4'>
                            {
                                data.options.map((opt, i) =>
                                    <p key={i} className="w-[40%] p-3 bg-gray-200 mb-3 rounded-sm shadow-md">
                                        <span className="font-bold mr-2">{String.fromCharCode(97 + i)})</span>
                                        {opt}
                                    </p>
                                )
                            }
                        </div>
                        <p className="mx-5 px-2 my-5">
                            <span className="font-bold">Answer: </span>
                            {(() => {
                                const answerIndex = data.options.indexOf(data.answer);
                                return `${String.fromCharCode(97 + answerIndex)}) ${data.answer}`;
                            })()}
                        </p>
                    </div>
                ) : (
                    <div className='w-full mx-2 p-4 flex flex-col'>
                        <h1 className='font-semibold text-sm'><span className='font-bold'>Q) </span>{data.question}</h1>
                        <h2 className='text-[10px] my-2'><span className='uppercase font-bold'>Instruction: </span>{data.instructions}</h2>
                        <div className="w-full m-3 h-44">
                            <p className='font-bold my-1 text-sm'>Predefine code:</p>
                            <CodeMirror
                                className="codeMirror-custom mt-1 border-4 border-gray-500 scrollbar-thin"
                                value={data.predefine}
                                theme={oneDark}
                                options={{
                                    lineNumbers: true,
                                }}
                                style={{
                                    height: '300px',
                                    overflow: 'auto',
                                }}
                            />
                            <p className='text-sm mt-5 pb-5'>
                                <span className='font-bold'>Expected Output: </span>
                                {data.answer}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ViewQuestion;
