import React, { useEffect, useState } from 'react'
import { RiSearch2Line } from "react-icons/ri";
import { MdOutlinePlaylistAdd } from "react-icons/md";
import { MdManageSearch } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { Spinner } from 'flowbite-react';
import ViewQuestion from '../modals/ViewQuestion';
import AddQuestionModal from '../modals/AddQuestionModal';

function DashQuestions() {

    const skillData = [

        {
            name: 'Java Developer',
            value: 'java',
            subjects: ['java'],
            type: ['mcq', 'coding']
        },
        {
            name: 'Data Analyst',
            value: 'dataanalyst',
            subjects: ['Python', 'Machine Learning', 'SQL', 'Excel', 'Power BI', 'SAP', 'ETL', 'Statistics'],
            type: ['mcq', 'coding']
        },
        {
            name: 'Business Analyst',
            value: 'businessAnalyst',
            subjects: ['Business Analysis', 'Tableau', 'Data Visualization', 'Agile', 'Jira', 'Scrum', 'Waterfall Methodology', 'SAP', 'ERP'],
            type: ['mcq']
        }

    ]

    const [query, setQuery] = useState({
        name: "",
        language: "",
        subject: "",
        type: "",
        level: ""
    });
    const [isAddQuestion, setIsAddQuestion] = useState(false);
    const [isSearchByQ, setIsSearchByQ] = useState(false);
    const [byQuestionType, setByQuestionType] = useState("");
    const [questionQuery, setQuestionQuery] = useState("");
    const [subjects, setSubjects] = useState([]);
    const [types, setTypes] = useState([]);

    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isViewQ, setIsViewQ] = useState(false);
    const [qData, setQData] = useState({});


    // select Skill func
    const handleSelectSkill = (e) => {

        const selectedValue = e.target.value;
        const selectedSkill = skillData.find(skill => skill.value === selectedValue);

        if (selectedSkill) {
            setQuery(prevQuery => ({
                ...prevQuery,
                language: selectedValue,
                name: selectedSkill.name
            }));
            setSubjects(selectedSkill.subjects);
            setTypes(selectedSkill.type);

        } else {
            setQuery(prevQuery => ({
                ...prevQuery,
                language: "",
                name: ""
            }));
            setSubjects([]);
            setTypes([]);
        }

        setErrorMessage("");
    };


    //Select Subject Func
    const handleSelectSubject = (e) => {
        const changedSub = e.target.value;
        setQuery((prev) => ({
            ...prev,
            subject: changedSub
        }))
        setErrorMessage("");
    }

    // select  Type func
    const handleSelectType = (e) => {
        const changedType = e.target.value;
        setQuery((prev) => ({
            ...prev,
            type: changedType
        }))
        setErrorMessage("");
    }

    // select  Level func
    const handleSelectLevel = (e) => {
        const changedLevel = e.target.value;
        setQuery((prev) => ({
            ...prev,
            level: changedLevel
        }))
        setErrorMessage("");
    }

    // select Search By Question Type
    const handleSlectByQuestionType = (e) => {
        setByQuestionType(e.target.value);
        setErrorMessage("");
    }

    const closeSearchByQuestion = () => {
        setIsSearchByQ(false);
        setQuestionQuery("");
    }

    //handle view Question 
    const handleViewQuestion = (q) => {
        setIsViewQ(true);
        setQData(q);
    }

    // ------------------------------ Search Function -------------------------
    const handleSearch = async (e) => {
        e.preventDefault();
        try {

            let url = "";

            if (query.type === 'coding') {
                url = `${process.env.REACT_APP_BACKEND}/api/coding/getFilteredQuestions?language=${query.language}&level=${query.level}`;
            } else if (query.type === 'mcq') {
                url = `${process.env.REACT_APP_BACKEND}/api/mcq/getFilteredQuestions?language=${query.language}&subject=${query.subject}`;
            }


            // if (query.language === "" || query.subject === "" || query.type) {
            //     setErrorMessage('Please Fill all the fields before submit.');
            //     return;
            // }

            setLoading(true);
            const res = await fetch(url, {
                method: 'GET',
                headers: { "Content-Type": "application/json" },
                credentials: "include",
            })

            const data = await res.json();

            if (res.status === 404) {
                setErrorMessage(data.message);
                setLoading(false);
                return
            } else if (res.status === 200) {
                setQuestions(data)
                setErrorMessage('');
                setLoading(false);

            } else {
                setErrorMessage('Internal server error. Try after some time.');
                setLoading(false);
            }
        } catch (err) {
            console.log('Error:::::', err)
        }
    }


    const searchByQuestion = async () => {

        console.log("Enter the Function")
        try {
            // if (byQuestionType === "") {
            //     setErrorMessage("Select the question type first")
            //     return;
            // }

            let url = "";
            if (byQuestionType === 'coding') {
                url = `${process.env.REACT_APP_BACKEND}/api/coding/searchByQuestion?keyword=${questionQuery}`;
            } else if (byQuestionType === 'mcq') {
                url = `${process.env.REACT_APP_BACKEND}/api/mcq/searchByQuestion?keyword=${questionQuery}`;
            }

            const res = await fetch(url, {
                method: 'GET',
                headers: { "Content-Type": "application/json" },
                credentials: "include",
            })

            const data = await res.json();

            if (res.status === 404) {
                setErrorMessage(data.message);
                return
            } else if (res.status === 200) {
                setQuestions(data)
                setErrorMessage('');
            } else {
                setErrorMessage('Internal server error. Try after some time.');
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    }



    // ------------- handled search by keywords ---------------------
    useEffect(() => {
        if (questionQuery.length >= 3) {
            searchByQuestion();
        }
    }, [questionQuery, byQuestionType])


    return (
        <>
            <div className=''>

                <form className='flex flex-col items-center' onSubmit={handleSearch} >
                    {isSearchByQ ?
                        // Search By question
                        (<div className='m-2 p-6 w-full border flex flex-col rounded-md border-gray-500'>
                            <span className='flex justify-end'>
                                <RxCross2 className='text-gray-400 hover:text-gray-800 right-0 transform -translate-y-3 translate-x-3'
                                    size={30}
                                    onClick={closeSearchByQuestion}
                                />
                            </span>
                            <div className='flex gap-5'>
                                <div className='w-[70%]'>
                                    <h1 className='font-semibold mb-3 text-xl'>Type question to search:</h1>


                                    <input className='w-full border-2 text-sm bg-gray-800 bg-transparent rounded-full py-2 px-5 '
                                        type="text"
                                        placeholder='Type more than 3 characters for search operation'
                                        onChange={(e) => setQuestionQuery(e.target.value)}
                                    />
                                </div>

                                <div className="flex flex-col w-36">
                                    <label className="text-xl font-semibold mb-3">Select Type:</label>
                                    <select
                                        className="p-2 uppercase text-xs border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                        onChange={handleSlectByQuestionType}
                                        value={byQuestionType}
                                    >
                                        <option className="text-xs" value="">Select Type</option>
                                        <option className="text-xs" value="mcq">MCQ</option>
                                        <option className="text-xs" value="coding">Coding</option>
                                    </select>
                                </div>

                            </div>
                        </div>) :

                        // search by parameter
                        (<div className='flex justify-center items-center gap-2 flex-wrap m-2 p-6 border rounded-md border-gray-500'>
                            <div className="flex flex-col w-52">
                                <label className="text-sm font-semibold mb-1 text-gray-700">Select Skill:</label>
                                <select
                                    onChange={handleSelectSkill}
                                    className="p-2 text-xs border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    value={query.language}
                                    disabled={loading}
                                    required
                                >
                                    <option className="text-xs" value="">Select Skill</option>
                                    {skillData.map((s, index) => (
                                        <option key={index} className="text-xs" value={s.value}>{s.name}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="flex flex-col w-52">
                                <label className="text-sm font-semibold mb-1 text-gray-700">Select Subject:</label>
                                <select
                                    onChange={handleSelectSubject}
                                    className="p-2 text-xs border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    value={query.subject}
                                    disabled={loading}
                                    required
                                >
                                    {subjects.length > 0 ? (
                                        subjects.map((subject, index) => (
                                            <option className="text-xs" value={subject} key={index}>
                                                {subject}
                                            </option>
                                        ))
                                    ) : (
                                        <option className="text-xs" value="">
                                            No subjects available
                                        </option>
                                    )}

                                </select>
                            </div>
                            <div className="flex flex-col w-52">
                                <label className="text-sm font-semibold mb-1 text-gray-700">Select Type:</label>
                                <select
                                    onChange={handleSelectType}
                                    className="p-2 uppercase text-xs border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    required
                                    disabled={loading}
                                    value={query.type}
                                >
                                    {types.map((t, index) => (
                                        <option key={index} className="text-xs uppercase" value={t}>{t}</option>
                                    ))}
                                </select>
                            </div>

                            {query.type === 'coding' &&
                                <div className="flex flex-col w-28">
                                    <label className="text-sm font-semibold mb-1 text-gray-700">Hardness:</label>
                                    <select
                                        onChange={handleSelectLevel}
                                        className="p-2 uppercase text-xs border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                        value={query.level}
                                        disabled={loading}
                                        required
                                    >
                                        <option className="text-xs" value="">Hardness</option>
                                        <option className="text-xs" value="easy">EASY</option>
                                        <option className="text-xs" value="medium">MEDIUM</option>
                                        <option className="text-xs" value="hard">HARD</option>
                                    </select>
                                </div>
                            }

                            <button className='bg-blue-500 text-gray-200 hover:text-white hover:bg-blue-700 flex gap-2 text-2xl items-center justify-center px-3 py-2 rounded-md ml-3 mt-5'
                                disabled={loading}
                                type='submit'
                            >{loading ? <Spinner size="sm" /> :
                                <RiSearch2Line className='' />}
                            </button>

                            {/* Buttons */}
                            <div className='flex justify-center gap-4 mt-4'>


                                <button className='bg-cyan-500 text-gray-200 hover:text-white hover:bg-cyan-700 flex gap-2 items-center justify-center px-5 py-2 rounded-md'
                                    type='button'
                                    disabled={loading}
                                    onClick={() => setIsAddQuestion(true)}
                                >
                                    <MdOutlinePlaylistAdd className='text-2xl' />
                                    <span className=''>Add Question</span>
                                </button>

                                <button className='bg-violet-600 text-gray-200 hover:text-white hover:bg-violet-800 flex gap-2 items-center justify-center px-5 py-2 rounded-md'
                                    type='button'
                                    disabled={loading}
                                    onClick={() => setIsSearchByQ(true)}
                                >
                                    <MdManageSearch className='text-2xl' />
                                    <span className=''>Search by question</span>
                                </button>
                            </div>
                        </div>)}

                </form>

                {/* Question section */}
                {questions.length !== 0 &&
                    <div className='flex justify-center flex-wrap gap-3'>

                        {questions.length > 0 &&
                            <div className='w-full bg-sky-600 rounded mx-3'>
                                <p className='text-white text-sm p-3'>Questions found: <span className='font-semibold'>{questions.length}</span></p>
                            </div>
                        }

                        {questions.map((q, i) => <div key={i}
                            onClick={() => handleViewQuestion(q)}
                            className='w-[45%] min-h-20 p-5 cursor-pointer bg-gray-200 shadow-sm rounded-md hover:scale-95 transition-all duration-300 ease-in-out hover:shadow-lg'>
                            {/* Categorise */}
                            <div className='w-full flex justify-between items-center'>
                                <h3 className='text-[9px] px-1 py-[2px] bg-slate-800 text-white rounded'>Skill: <span className='uppercase font-semibold'>{q.language}</span></h3>
                                <div className='flex gap-2 justify-end  transform -translate-y-3 translate-x-3'>
                                    <p className='px-2 py-1 rounded-lg bg-sky-600 text-white text-[9px]'>Sub: <span className='uppercase font-semibold'>{q.subject ? q.subject : q.language}</span></p>
                                    <p className='px-2 py-1 rounded-lg bg-sky-600 text-white uppercase text-[9px]'>{q.type}</p>

                                    {q.type === 'coding' && <p className={`px-2 py-1 rounded-lg ${q.level === 'easy' ? 'bg-green-500' :
                                        q.level === 'medium' ? 'bg-blue-600' : 'bg-red-600'} text-white uppercase text-[9px]`}>{q.level}</p>}
                                </div>
                            </div>

                            <h1 className='text-sm'><span className='font-bold'>Q) </span>{q.question}</h1>
                        </div>)}

                    </div>
                }

                {errorMessage && <p className='text-base text-center my-10'>{errorMessage}</p>}


            </div>
            {isViewQ && <ViewQuestion data={qData} showModal={setIsViewQ} />}
            {isAddQuestion && <AddQuestionModal showModal={setIsAddQuestion} />}
        </>
    )
}

export default DashQuestions