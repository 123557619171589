import React, { useState, useEffect } from "react";
import { Button, Modal, Table, TextInput } from "flowbite-react";
import { AiOutlineSearch } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { HiOutlineExclamationCircle } from "react-icons/hi";

export default function DashUsers() {

    const { users } = useSelector((state) => state.admin);
    const { currentUser } = useSelector((state) => state.user);

    const [query, setQuery] = useState('');
    const [searchedUsers, setSearchedUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showMore, setShowMore] = useState(true);
    const [userIdToDelete, setUserIdToDelete] = useState('');
    const [showModal, setShowModal] = useState(false);

    // Debounce function to limit the number of API calls while typing
    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    // Function to fetch users from the backend
    const fetchUsers = async (searchQuery) => {
        if (searchQuery.length < 3) {
            setSearchedUsers([...users]);  // Populate searchedUsers with users initially if query is less than 3
            return;
        }

        setLoading(true);  // Show loading spinner while fetching data
        setError('');      // Reset any previous errors

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/searchUser?query=${searchQuery}`, {
                method: 'GET',
                headers: { "Content-Type": "application/json" },
                credentials: "include",
            });
            const data = await response.json();
            if (response.ok) {
                setSearchedUsers(data.users);  // Update users with the fetched data
            } else {
                setSearchedUsers([]);  // Reset users if no users are found
                setError(data.message || 'No users found');
            }
        } catch (error) {
            setError('Failed to fetch users. Please try again.');
        } finally {
            setLoading(false);  // Hide loading spinner
        }
    };

    // Wrap fetchUsers with the debounce function to limit API calls
    const debouncedFetchUsers = debounce(fetchUsers, 300);

    //-------------------- Handle DELETE USER ------------------------
    const handleDeleteUser = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/deleteUser/${userIdToDelete}`, {
                method: 'DELETE',
                headers: { "Content-Type": "application/json" },
                credentials: 'include',
            });

            const data = await res.json();

            if (!res.ok) {
                console.log(data);
                setError(data.message || 'Failed to delete user.');
                return;
            }

            // If the request is successful, remove the user from the local state
            setSearchedUsers((prev) => prev.filter((user) => user._id !== userIdToDelete));
            setShowModal(false);
        } catch (err) {
            setError('An error occurred while deleting the user. Please try again.');
            setShowModal(false);
        }
    }

    //------------------------------------ Handle SHOW MORE ----------------------------------
    const handleShowMore = async () => {
        const startIndex = searchedUsers.length;  // Get the current length of searchedUsers

        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/getUsers?startIndex=${startIndex}&query=${query}`, {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' }
            });
            const data = await res.json();

            if (res.ok) {
                if (data.users && data.users.length > 0) {
                    // Append the new users to the existing searchedUsers state
                    setSearchedUsers((prev) => [...prev, ...data.users]);
                    // Hide the "Show More" button if less than 9 users are returned
                    if (data.users.length < 9) {
                        setShowMore(false);
                    }
                } else {
                    setShowMore(false); // No more users to show
                }
            } else {
                console.error(data.message || 'Failed to fetch more users.');
            }
        } catch (error) {
            console.error('An error occurred while fetching more users:', error);
        }
    };



    // Populate searchedUsers with all users initially from the Redux store
    useEffect(() => {
        setSearchedUsers([...users]);  // Ensure users are loaded initially from Redux
    }, [users]);

    // UseEffect to trigger the API call when query changes
    useEffect(() => {
        if (query.length >= 3) {
            debouncedFetchUsers(query);
        } else {
            setSearchedUsers([...users]);  // Reset to initial users if query is less than 3 characters
        }
    }, [query, users]);  // Added users to the dependency array

    return (
        <div className="p-3 mx-auto" >
            <div className="w-full flex-col justify-center items-center">
                {/* Search Area */}
                <div className="mx-auto w-3/4">
                    <h2 className="text-lg text-gray-700 pl-3 pb-2">Search user by email or name:</h2>
                    <form className="">
                        <TextInput
                            type="text"
                            placeholder="Search..."
                            rightIcon={AiOutlineSearch}
                            className="hidden lg:inline"
                            value={query}  // Controlled input
                            onChange={(e) => setQuery(e.target.value)} // Handle input change
                        />
                        {error && <p className="text-red-500 text-xs p-1">{error}</p>}
                    </form>
                </div>

                {/* Search */}
                <div>
                    {loading && <p>Loading...</p>}
                </div>

                {/* Users Table */}
                <div className="mt-4">
                    {/* Show message if no users are found */}
                    {searchedUsers.length === 0 && query.length >= 3 && !loading && !error && (
                        <p>No users found.</p>
                    )}
                </div>

                <div className='h-auto table-auto overflow-x-scroll scrollbar-none md:mx-auto p-3'>
                    {currentUser.isAdmin && searchedUsers.length > 0 ? (
                        <>
                            {/* <h2 className="text-lg text-gray-700 font-semibold py-2 px-2">Latest Users:</h2> */}
                            <Table hoverable className="shadow-md mt-3">
                                <Table.Head>
                                    <Table.HeadCell>Date created</Table.HeadCell>
                                    <Table.HeadCell>User image</Table.HeadCell>
                                    <Table.HeadCell>Username</Table.HeadCell>
                                    <Table.HeadCell>Email</Table.HeadCell>
                                    <Table.HeadCell>Type</Table.HeadCell>
                                    <Table.HeadCell>Delete</Table.HeadCell>
                                </Table.Head>
                                {searchedUsers.map((user) => (
                                    <Table.Body className="divide-y" key={user._id}>
                                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell>
                                                {new Date(user.createdAt).toLocaleDateString()}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <img src={user.profilePicture} alt={user.name} className="w-10 h-10 object-cover rounded-full" />
                                            </Table.Cell>
                                            <Table.Cell className="font-medium text-gray-900 dark:text-gray-300">
                                                <Link>{user.name}</Link>
                                            </Table.Cell>
                                            <Table.Cell className="font-medium text-gray-900 dark:text-gray-300">
                                                {user.email}
                                            </Table.Cell>
                                            <Table.Cell>{user.isAdmin ? 'admin' : 'user'}</Table.Cell>
                                            <Table.Cell>
                                                <span className="text-red-500 hover:underline cursor-pointer"
                                                    onClick={() => {
                                                        setUserIdToDelete(user._id);
                                                        setShowModal(true);
                                                    }}
                                                >
                                                    Delete
                                                </span>
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                ))}
                            </Table>

                        </>
                    ) : (
                        <p className="text-center">No users to show !</p>
                    )}
                </div>
                {showMore && (<Button onClick={handleShowMore} className="mx-auto my-3" >
                    Show more
                </Button>)}
            </div>
            <Modal show={showModal}
                onClose={() => setShowModal(false)}
                popup
                size='md'
            >
                <div className='text-center'>
                    <Modal.Header />
                    <Modal.Body>
                        <HiOutlineExclamationCircle className='h-14 w-14 mx-auto mb-4 text-gray-500 dark:text-gray-300' />
                        <h3 className='mb-3 text-gray-700 text-center text-lg'>Are you sure you want to delete this user?</h3>
                        <p className="mb-3 font-bold">Warning: this action is irreversible</p>
                    </Modal.Body>
                    <Modal.Footer className="justify-center">
                        <Button
                            className="bg-red-500"
                            onClick={handleDeleteUser}
                        >
                            Confirm
                        </Button>
                        <Button
                            className="bg-gray-300"
                            onClick={() => setShowModal(false)}
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </div>
    );
}
