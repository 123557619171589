import React, { useEffect } from 'react'
import { RxCross2 } from 'react-icons/rx'
import { MdOutlineTimer } from "react-icons/md";
import { BsBookmarkStarFill } from "react-icons/bs";
import { PiCodeFill } from "react-icons/pi";
import { RiCheckboxMultipleFill } from "react-icons/ri";
import { GiBullseye } from "react-icons/gi";
import { FaArrowRightLong } from 'react-icons/fa6';
import { div } from '@tensorflow/tfjs';
import { Link } from 'react-router-dom';

function PreTestDetails({ showModal, data, setData, currentUser }) {

    const attempt = currentUser?.userInfo?.testStatus?.[data.language]?.[data.level] ?? 2;
    console.log("attempt:: ", attempt)

    const setDataInRedux = () => {
        setData(data)
    }

    useEffect(() => {
        // Prevent background scroll when modal is open
        document.body.style.overflow = 'hidden';
        return () => {
            // Restore background scroll when modal is closed
            document.body.style.overflow = '';
        };
    }, []);

    return (

        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center mt-14">
            <div className="bg-white w-[60%] h-[90%] shadow-lg rounded-lg p-6 relative overflow-y-auto overflow-x-hidden scrollbar-none ">

                {/* Navigation */}
                <span className='flex justify-end'>
                    <RxCross2 className='text-gray-400 hover:text-gray-800 right-0 transform -translate-y-3 translate-x-3'
                        size={25}
                        onClick={() => showModal(false)}
                    />
                </span>
                <div className='flex flex-col items-center'>
                    <h1 className='font-bold text-2xl text-center text-white mb-5 bg-blue-600 py-3 px-5 rounded shadow-md'>
                        {` ${data.language === 'java' ? 'Java' : data.language === 'dataanalyst' ? 'Data-Analyst' : data.language === 'businessAnalyst' ? 'Business-Analyst' : data.language === 'businessIntelligence' ? 'Business-Intelligence' : data.language === 'uiux' ? 'UI/UX' : data.language}`} <span>({data.level}) Test Details</span>
                    </h1>

                    <p className="text-2xl mb-6 font-normal text-gray-800 flex justify-start items-center">
                        <GiBullseye className='text-red-700 mr-2 flex-shrink-0' size={45} />
                        <span>Attempts Left: <b>{" "}{attempt}</b></span>
                    </p>

                    <div className='flex flex-col items-start'>
                        <p className="text-base mb-3 font-normal text-gray-800 flex justify-start items-center">
                            <MdOutlineTimer className='text-blue-800 mr-2 flex-shrink-0' size={25} />
                            <span>Time: <b>{data.time} minutes.</b></span>
                        </p>
                        <p className="text-base mb-3 font-normal text-gray-800 flex justify-start items-center">
                            <BsBookmarkStarFill className='text-blue-800 mr-2 flex-shrink-0' size={22} />
                            <span>Full marks: <b>{(data.numOfMCQ * 10) + (data.numOfCoding * 20)}</b></span>
                        </p>
                        {data.numOfCoding !== 0 && <p className="text-base mb-3 font-normal text-gray-800 flex justify-start items-center">
                            <PiCodeFill className='text-blue-800 mr-2 flex-shrink-0' size={25} />
                            <span>Coding problems: <b>{data.numOfCoding}</b></span>
                        </p>}
                        <p className="text-base mb-3 font-normal text-gray-800 flex justify-start items-center">
                            <RiCheckboxMultipleFill className='text-blue-800 mr-2 flex-shrink-0' size={25} />
                            <span>MCQ: <b>{data.numOfMCQ}</b></span>
                        </p>
                    </div>

                    {attempt <= 0 ? <p className='mt-3 text-center'>
                        You have completed all your test attempts. <br />
                        Please <Link to="/talk-to-expert" className='text-sky-600 font-semibold cursor-pointer hover:underline'> contact us </Link>
                        for further assistance.
                    </p> :
                        <div className='flex flex-col items-center gap-3 mt-3'>
                            <p className='text-gray-700 text-sm text-center'>
                                We will consider only the best result from your two attempts.
                            </p>
                            <button
                                onClick={setDataInRedux}
                                className="group font-josefinSans max-w-44  text-white px-4 mr-5 py-2 bg-[#1d98df] rounded-sm relative overflow-hidden hover:bg-[#3183b3]"
                            >
                                Take the test
                                <FaArrowRightLong
                                    size={20}
                                    className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                                />
                            </button>
                        </div>}
                </div>
            </div>
        </div>

    )
}

export default PreTestDetails