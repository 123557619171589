import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";
import { IoExtensionPuzzleOutline, IoLogIn } from "react-icons/io5";
import {
  MdDoNotDisturbAlt,
  MdOutlineHighlight,
  MdOutlineQuestionMark,
} from "react-icons/md";
import { TiDocumentText } from "react-icons/ti";
import { HiOutlineCursorClick } from "react-icons/hi";
import { SiTestrail } from "react-icons/si";
import { IoMdCheckboxOutline } from "react-icons/io";
import { MdOutlineSwitchAccessShortcut } from "react-icons/md";
import { FaArrowRightLong, FaClipboardQuestion } from "react-icons/fa6";
import { TbKeyframes } from "react-icons/tb";
import { BiSolidReport } from "react-icons/bi";
import { GrCertificate } from "react-icons/gr";
import { PiExam } from "react-icons/pi";
import { GiBinoculars } from "react-icons/gi";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function HowItWorks() {
  useEffect(() => {
    // Register GSAP ScrollTrigger
    gsap.registerPlugin(ScrollTrigger);

    // Select all elements with the 'about-us' class
    const popUpwardsSections = document.querySelectorAll(".pop-upwards");
    const leftToRightSections = document.querySelectorAll(".left-to-right");
    const rightToLeftSections = document.querySelectorAll(".right-to-left");
    const rotateClockwiseSections =
      document.querySelectorAll(".rotate-clockwaise");
    const flip360Sections = document.querySelectorAll(".flip-360");

    popUpwardsSections.forEach((section, index) => {
      gsap.fromTo(
        section,
        { opacity: 0, y: 40 },
        {
          opacity: 1,
          y: 0,
          duration: 1.3,
          ease: "power3.out",
          scrollTrigger: {
            trigger: section,
            start: "top 90%",
            toggleActions: "play none none none",
            once: true,
          },
          delay: index * 0.2,
        }
      );
    });

    leftToRightSections.forEach((section, index) => {
      gsap.fromTo(
        section,
        { opacity: 0, x: -40 },
        {
          opacity: 1,
          x: 0,
          duration: 1,
          ease: "power3.out",
          scrollTrigger: {
            trigger: section,
            start: "top 95%",
            toggleActions: "play none none none",
            once: true,
          },
          delay: index * 0.2,
        }
      );
    });

    rightToLeftSections.forEach((section, index) => {
      gsap.fromTo(
        section,
        { opacity: 0, x: 40 },
        {
          opacity: 1,
          x: 0,
          duration: 1,
          ease: "power3.out",
          scrollTrigger: {
            trigger: section,
            start: "top 95%",
            toggleActions: "play none none none",
            once: true,
          },
          delay: index * 0.2,
        }
      );
    });

    rotateClockwiseSections.forEach((section, index) => {
      gsap.fromTo(
        section,
        { opacity: 0, rotation: 0 },
        {
          opacity: 1,
          rotation: 90,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: section,
            start: "top 95%",
            toggleActions: "play none none none",
            once: true,
          },
          delay: index * 0.2,
        }
      );
    });

    flip360Sections.forEach((section, index) => {
      gsap.fromTo(
        section,
        { opacity: 0, rotationY: 0 },
        {
          opacity: 1,
          rotationY: 360, // Flip 360 degrees
          duration: 2.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: section,
            start: "top 95%",
            toggleActions: "play none none none",
            once: false,
          },
          delay: index * 0.8,
          repeat: -1,
          repeatDelay: 3,
        }
      );
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>How the Horg Skill Test Works: A Step-by-Step Guide</title>
        <meta
          name="description"
          content="Learn everything about how the skill test works. Get a clear breakdown of the process, key steps, and expert advice to perform your best."
        />
      </Helmet>
      <div className="h-full w-full flex flex-col items-center">
        {/* Everyting you need to know */}
        <div className="w-full md:max-w-[1140px] mx-auto flex flex-col items-center font-semibold">
          <div className=" mt-16 mx-48 px-14 flex flex-row justify-center gap-5 pb-8 border-b border-gray-400">
            <div className="text-center text-gray-800 ">
              <p className="text-7xl pop-upwards">Everything</p> <br />
              <p className="text-5xl pop-upwards">You Need To</p>
              <br />
              <p className="text-9xl pop-upwards">KNOW</p>
            </div>
            {/* <img className="h-52" src="./images/instruction/cross-board.webp" alt="crossroad-sign" /> */}
          </div>

          <div className="mt-12 mb-6 flex flex-row items-center justify-center gap-4 ">
            <h2 className="text-3xl text-center text-gray-700 left-to-right">
              Key Highlights
            </h2>
            <MdOutlineHighlight
              className="right-to-left rotate-clockwaise text-gray-700"
              size={65}
            />
          </div>

          <div className="h-auto px-4 md:px-12 flex flex-row flex-wrap justify-center items-center gap-4">
            <div className="relative w-40 h-60 bg-[#fffae4] rounded-lg shadow-md flex flex-col justify-end items-center left-to-right hover:scale-110 hover:shadow-lg transform transition-transform transition-shadow duration-300 ease-in-out">
              <img
                className="absolute h-full w-full object-cover transform -translate-y-3"
                src="./images/instruction/fast-result.webp"
                alt="fast-result"
              />
              <p className="relative text-xs font-semibold z-10 text-[#e58a2f] mb-3">
                FAST RESULT
              </p>
            </div>

            <div className="relative w-40 h-60 bg-[#d9c9ff] rounded-lg shadow-md flex flex-col justify-end items-center left-to-right hover:scale-110 hover:shadow-lg transform transition-transform transition-shadow duration-300 ease-in-out">
              <img
                className="absolute h-full w-full object-cover transform -translate-y-4"
                src="./images/instruction/custom-test.webp"
                alt="custom-test"
              />
              <p className="relative text-center text-xs font-semibold z-10 text-[#572ac1] my-3 uppercase">
                custom test <br />
                by recruiters
              </p>
            </div>

            <div className="relative w-40 h-60 bg-[#c8fffc] rounded-lg shadow-md flex flex-col justify-end items-center left-to-right hover:scale-110 hover:shadow-lg transform transition-transform transition-shadow duration-300 ease-in-out">
              <img
                className="absolute h-full w-full object-cover"
                src="./images/instruction/recruite.webp"
                alt="fast-result"
              />
              <p className="relative text-center text-xs font-semibold z-10 text-[#1d958f] my-3 uppercase">
                Hired by <br />
                top companies
              </p>
            </div>

            <div className="relative w-40 h-60 bg-[#ffcfcd] rounded-lg shadow-md flex flex-col justify-end items-center left-to-right hover:scale-110 hover:shadow-lg transform transition-transform transition-shadow duration-300 ease-in-out">
              <img
                className="absolute h-full w-full object-cover transform -translate-y-1"
                src="./images/instruction/certificate.webp"
                alt="certificate"
              />
              <p className="relative text-center text-xs font-semibold z-10 text-[#801b17] my-3 uppercase">
                Certificate after <br />
                Pass the Test
              </p>
            </div>

            <div className="relative w-40 h-60 bg-[#84d2ff] rounded-lg shadow-md flex flex-col justify-end items-center left-to-right hover:scale-110 hover:shadow-lg transform transition-transform transition-shadow duration-300 ease-in-out">
              <img
                className="absolute h-full w-full object-cover transform -translate-y-6"
                src="./images/instruction/security.webp"
                alt="fast-result"
              />
              <p className="relative text-center text-xs font-semibold z-10 text-[#206b96] my-3 uppercase">
                Secure Payment <br />& <br />
                No cheat Skill-Tests
              </p>
            </div>
          </div>
        </div>

        {/* Skill Test Instructions */}
        <div className="w-full md:max-w-[1140px] flex flex-col justify-center">
          <div className="mt-28 mb-6 flex flex-row items-center justify-center gap-4 ">
            <h2 className="left-to-right text-3xl text-center font-semibold text-gray-700 ">
              How Skill Test Works?
            </h2>
            <IoExtensionPuzzleOutline
              className="right-to-left rotate-clockwaise text-gray-700"
              size={65}
            />
          </div>

          <div className="h-auto flex flex-row flex-wrap justify-center mx-20">
            <div className="w-52 h-52 bg-white rounded-md border-2 border-[#122363] m-2 right-to-left flex flex-col items-center justify-start">
              <div className="flex flex-col items-center justify-start p-2">
                <IoLogIn className="text-blue-700 my-5 flip-360" size={40} />
                <p className="text-sm font-semibold text-[#122363] text-center">
                  SignIn <br /> or <br />
                  SignUp as "Job Seeker"
                </p>
              </div>
            </div>

            <div className="w-52 h-52 bg-white rounded-md border-2 border-[#122363] m-2 right-to-left flex flex-col items-center justify-start">
              <div className="flex flex-col items-center justify-start p-2">
                <TiDocumentText
                  className="text-blue-700 my-5 flip-360"
                  size={40}
                />
                <p className="text-sm font-semibold text-[#122363] text-center">
                  Provide <br /> User Details <br />
                  Including VISA Status
                </p>
              </div>
            </div>

            <div className="w-52 h-52 bg-white rounded-md border-2 border-[#122363] m-2 right-to-left flex flex-col items-center justify-start">
              <div className="flex flex-col items-center justify-start p-2">
                <HiOutlineCursorClick
                  className="text-blue-700 my-5 flip-360"
                  size={40}
                />
                <p className="text-sm font-semibold text-[#122363] text-center">
                  Choose <br /> Your <br />
                  Desired Skill
                </p>
              </div>
            </div>

            <div className="w-52 h-52 bg-white rounded-md border-2 border-[#122363] m-2 right-to-left flex flex-col items-center justify-start">
              <div className="flex flex-col items-center justify-start p-2">
                <SiTestrail className="text-blue-700 my-5 flip-360" size={40} />
                <p className="text-sm font-semibold text-[#122363] text-center">
                  Conduct The <br /> Skill-Test with
                  <br />
                  Camera & Microphone Access
                </p>
              </div>
            </div>

            <div className="w-52 h-52 bg-white rounded-md border-2 border-[#122363] m-2 right-to-left flex flex-col items-center justify-start">
              <div className="flex flex-col items-center justify-start p-2">
                <IoMdCheckboxOutline
                  className="text-blue-700 my-5 flip-360"
                  size={40}
                />
                <p className="text-sm font-semibold text-[#122363] text-center">
                  Submit
                  <br /> Skill-Test & Collect
                  <br />
                  Your <br />
                  Certificate & Report Card
                </p>
              </div>
            </div>

            <div className="w-52 h-52 bg-white rounded-md border-2 border-[#122363] m-2 right-to-left flex flex-col items-center justify-start">
              <div className="flex flex-col items-center justify-start p-2">
                <MdOutlineSwitchAccessShortcut
                  className="text-blue-700 my-5 flip-360"
                  size={40}
                />
                <p className="text-sm font-semibold text-[#122363] text-center">
                  Yay !<br /> Now You are
                  <br />
                  Eligible to <br />
                  View by Recruiters
                </p>
              </div>
            </div>
          </div>

          <p className="text-sm text-gray-800 text-center px-10 py-12 pop-upwards">
            Sign in or sign up as a "Job Seeker" on h-or-g.com, and provide your
            details, including your VISA status. Choose the skill you wish to be
            tested on from a wide range of industry-specific assessments. During
            the test, camera and microphone access are required for our
            proctoring system, ensuring test integrity by monitoring for any
            violations. Once you complete and submit the test, you’ll receive a
            Certificate of Excellence and a detailed performance report. After
            passing, you become eligible for recruiters to view your profile and
            consider you for relevant job opportunities.
          </p>

          <a className="w-48 my-4 mx-auto pop-upwards" href="/selectSkill">
            <div className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-full relative overflow-hidden hover:bg-blue-800 cursor-pointer flex justify-between items-center">
              <span>Take the test</span>
              <FaArrowRightLong
                size={20}
                className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
              />
            </div>
          </a>
        </div>

        {/* Highlights of Skill-Test */}
        <div className="w-full md:max-w-[1140px] flex flex-col justify-center">
          <div className="mt-20 mb-6 flex flex-row items-center justify-center gap-4 ">
            <h2 className="left-to-right text-3xl text-center font-semibold text-gray-700 ">
              Featues of Skill-Test?
            </h2>
            <TbKeyframes className="right-to-left text-gray-700" size={65} />
          </div>

          <div className="flex justify-center flex-wrap mt-6">
            {/* Card 1 */}
            <div className="relative w-72 h-52 bg-gradient-to-b from-zinc-400 to-white rounded-xl m-4 mb-12  flex flex-col items-center justify-start left-to-right">
              <div className="absolute flex flex-col items-center p-2">
                <div className="relative flex items-center justify-center w-16 h-16 bg-[#122363] rounded-full transform -translate-y-10 border-4 border-white">
                  <FaClipboardQuestion className="text-white" size={28} />
                </div>
                <div className="-mt-5">
                  <h2 className="relative text-base font-semibold text-[#122363] text-center mt-2">
                    Questions
                  </h2>
                  <p className="relative text-sm px-4 text-zinc-700 font-medium text-center mt-2">
                    We deliver top-quality questions curated by industry
                    experts, ensuring the best resources to test skills, enhance
                    knowledge, and prepare for real-world challenges.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 2 */}
            <div className="relative w-72 h-52 bg-gradient-to-b from-zinc-400 to-white rounded-xl m-4 mb-12  flex flex-col items-center justify-start left-to-right">
              <div className="absolute flex flex-col items-center p-2">
                <div className="relative flex items-center justify-center w-16 h-16 bg-[#122363] rounded-full transform -translate-y-10 border-4 border-white">
                  <PiExam className="text-white" size={28} />
                </div>
                <div className="-mt-5">
                  <h2 className="relative text-base font-semibold text-[#122363] text-center mt-2">
                    Performance Report
                  </h2>
                  <p className="relative text-sm px-4 text-zinc-700 font-medium text-center mt-2">
                    Receive instant performance reports highlighting your
                    strengths and areas for improvement, helping you focus on
                    what matters most for growth and success.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 3 */}
            <div className="relative w-72 h-52 bg-gradient-to-b from-zinc-400 to-white rounded-xl m-4 mb-12  flex flex-col items-center justify-start left-to-right">
              <div className="absolute flex flex-col items-center p-2">
                <div className="relative flex items-center justify-center w-16 h-16 bg-[#122363] rounded-full transform -translate-y-10 border-4 border-white">
                  <GrCertificate className="text-white" size={28} />
                </div>
                <div className="-mt-5">
                  <h2 className="relative text-base font-semibold text-[#122363] text-center mt-2">
                    Certification
                  </h2>
                  <p className="relative text-sm px-4 text-zinc-700 font-medium text-center mt-2">
                    Pass the Hard test in any skill and earn a Certificate of
                    Excellence, showcasing your expertise and highlighting your
                    exceptional abilities to stand out.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 4 */}
            <div className="relative w-72 h-52 bg-gradient-to-b from-zinc-400 to-white rounded-xl m-4 mb-12  flex flex-col items-center justify-start right-to-left">
              <div className="absolute flex flex-col items-center p-2">
                <div className="relative flex items-center justify-center w-16 h-16 bg-[#122363] rounded-full transform -translate-y-10 border-4 border-white">
                  <GiBinoculars className="text-white" size={28} />
                </div>
                <div className="-mt-5">
                  <h2 className="relative text-base font-semibold text-[#122363] text-center mt-2">
                    Proctoring
                  </h2>
                  <p className="relative text-sm px-4 text-zinc-700 font-medium text-center mt-2">
                    Our proctoring system monitors eye movement, camera
                    presence, and violations, displaying results to assist
                    recruiters in selecting the most suitable candidates
                    effectively.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 5 */}
            <div className="relative w-72 h-52 bg-gradient-to-b from-zinc-400 to-white rounded-xl m-4 mb-12  flex flex-col items-center justify-start right-to-left">
              <div className="absolute flex flex-col items-center p-2">
                <div className="relative flex items-center justify-center w-16 h-16 bg-[#122363] rounded-full transform -translate-y-10 border-4 border-white">
                  <MdDoNotDisturbAlt className="text-white" size={28} />
                </div>
                <div className="-mt-5">
                  <h2 className="relative text-base font-semibold text-[#122363] text-center mt-2">
                    No-Cheat Test
                  </h2>
                  <p className="relative text-sm px-4 text-zinc-700 font-medium text-center mt-2">
                    Users cannot exit fullscreen mode or switch tabs. Any such
                    action triggers automatic test submission, ensuring a secure
                    and fair testing environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative w-full max-w-[1140px] mx-auto">
          <div className="relative w-full">
            <img
              className="absolute rounded-lg shadow-md"
              src="./images/hit-1.webp"
              alt=""
            />
            <div className="relative z-20 h-[350px] w-1/2 mt-6 mb-4 flex flex-col items-center justify-center text-white">
              <h2 className="font-semibold text-6xl">Hire</h2>
              <h2 className="font-semibold text-3xl">Top-Tier</h2>
              <h2 className="font-semibold text-7xl">Talent</h2>
              <p className="font-semibold text-xl text-center">
                Validated through comprehensive skill tests
              </p>

              <Link
                to="/viewPlans"
                className="group font-josefinSans text-[#0069bc] text-sm px-5 mr-5 py-2 bg-white rounded-sm relative overflow-hidden hover:bg-gray-200 flex justify-between my-4"
              >
                <p>View Pricing</p>
                <FaArrowRightLong
                  size={20}
                  className="text-[#0069bc] mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
