import React, { useEffect, useState } from 'react'
import { RxCross2 } from 'react-icons/rx';
import { FaArrowLeftLong } from "react-icons/fa6";
import { HiInformationCircle } from 'react-icons/hi';
import { Alert, Spinner } from 'flowbite-react';

function AddQuestionModal({ showModal }) {

    const skillData = [
        {
            name: 'Java Developer',
            value: 'java',
            subjects: ['java'],
            type: ['mcq', 'coding']
        },
        {
            name: 'Data Analyst',
            value: 'dataanalyst',
            subjects: ['Python', 'Machine Learning', 'SQL', 'Excel', 'Power BI', 'SAP', 'ETL', 'Statistics'],
            type: ['mcq', 'coding']
        },
        {
            name: 'Business Analyst',
            value: 'businessAnalyst',
            subjects: ['Business Analysis', 'Tableau', 'Data Visualization', 'Agile', 'Jira', 'Scrum', 'Waterfall Methodology', 'SAP', 'ERP'],
            type: ['mcq']
        }
    ]

    const [type, setType] = useState('');
    const [isTypeSelected, setIsTypeSelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedSkill, setSelectedSkil] = useState(null);
    const [formData, setFormData] = useState({
        question: "",
        language: "",
        subject: "",
        options: ["", "", "", ""], // Array for 4 options
        answer: "",
    });
    const [codingFormData, setCodingFormData] = useState({
        question: "",
        instructions: "",
        language: "",
        answer: "",
        predefine: "",
        level: ""
    });
    const [savedQuestion, setSavedQuestion] = useState(null);
    const [isSaved, setIsSaved] = useState(false);

    //--------- Procee towards Adding Question --------------------
    const handleProceed = () => {
        if (type === '') {
            return setErrorMessage("Please select a type to proceed.")
        } else {
            setErrorMessage("");
            setIsTypeSelected(true);
        }
    }

    // Prevent background scroll when modal is open
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = '';
        };
    }, []);


    const handleSelectSkill = (e) => {
        const { name, value } = e.target;
        const selectedSkill = skillData.find(skill => skill.value === value)
        setFormData({ ...formData, [name]: value });
        setSelectedSkil(selectedSkill);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrorMessage("");
    };

    const handleCodingFormChange = (e) => {
        const { name, value } = e.target;
        setCodingFormData({ ...codingFormData, [name]: value });
        setErrorMessage("");
    }

    const handleOptionChange = (index, value) => {
        const updatedOptions = [...formData.options];
        updatedOptions[index] = value;
        setFormData({ ...formData, options: updatedOptions });
    };

    //----------------------- ------ SUBMIT MCQ QUESTION -----------------------------
    const handleMcqSubmit = async (e) => {
        e.preventDefault();

        const newFormData = {
            ...formData, level: "easy",
            marks: 10,
            type: 'mcq'
        }

        try {
            setLoading(true);

            if (newFormData.question === '' || newFormData.options.length === 0 || newFormData.language === '' || newFormData.level === '' || newFormData.type === '' || newFormData < 0 || newFormData === '' || newFormData.subject === '') {
                setErrorMessage("Please fill all the fields.");
                setLoading(false);
                return
            }

            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/mcq/addQuestion`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                credentials: "include",
                body: JSON.stringify(newFormData)
            })
            const data = await res.json();

            if (res.status === 404) {
                setErrorMessage(data.message);
                setLoading(false);
                return
            } else if (res.status === 200) {
                setSavedQuestion(data.question);
                setFormData({
                    question: "",
                    language: "",
                    subject: "",
                    options: ["", "", "", ""], // Array for 4 options
                    answer: "",
                })
                setIsSaved(true)
                setErrorMessage('');
                setLoading(false);

            } else {
                setErrorMessage('Internal server error. Try after some time.');
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    // --------------------------- SUBMIT CODING QUESTION ----------------------------
    const handleCodingQuestion = async (e) => {
        e.preventDefault();

        const newFormData = {
            ...codingFormData,
            type: "coding",
            marks: codingFormData.level === 'easy' ? 20 : codingFormData.level === 'medium' ? 30 : 40
        }

        try {
            setLoading(true);
            if (newFormData.question === '' || newFormData.answer === "" || newFormData.marks < 0 || newFormData.language === '' || newFormData.type === '' || newFormData.predefine === '' || newFormData.instructions === '' || newFormData.level === '') {
                setErrorMessage("Please Fill all the fields.")
                setLoading(false);
                return;
            }

            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/coding/addQuestion`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                credentials: "include",
                body: JSON.stringify(newFormData)
            })
            const data = await res.json();

            if (res.status === 404) {
                setErrorMessage(data.message);
                setLoading(false);
                return
            } else if (res.status === 200) {
                setSavedQuestion(data.question);
                setCodingFormData({
                    question: "",
                    instructions: "",
                    language: "",
                    answer: "",
                    predefine: "",
                    level: ""
                })
                setIsSaved(true)
                setErrorMessage('');
                setLoading(false);

            } else {
                setErrorMessage('Internal server error. Try after some time.');
                setLoading(false);
            }
        } catch (error) {

            console.log(error);
        }
    }

    const handleAddMoreQuestion = () => {
        setFormData({
            question: "",
            language: "",
            subject: "",
            options: ["", "", "", ""],
            answer: ""
        })

        setIsSaved(false);

    }

    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center mt-14">
            <div className="bg-white w-[70%] h-[90%] shadow-lg rounded-lg p-6 relative overflow-y-auto overflow-x-hidden scrollbar-none ">

                {/* Navigation */}
                <span className={`flex ${isTypeSelected ? 'justify-between' : 'justify-end'}`}>
                    {isTypeSelected && <FaArrowLeftLong className='text-gray-400 hover:text-gray-800 right-0 transform -translate-y-3 translate-x-3'
                        size={20}
                        onClick={() => setIsTypeSelected(false)}
                    />}
                    <RxCross2 className='text-gray-400 hover:text-gray-800 right-0 transform -translate-y-3 translate-x-3'
                        size={25}
                        onClick={() => showModal(false)}
                    />
                </span>

                {/* ------------- Select Type section --------------- */}
                {/*               If Question type is not selected */}
                {!isTypeSelected ? <div className='h-full w-full flex flex-col justify-center items-center'>
                    <div className='flex flex-col items-center justify-center'>
                        <h1 className='text-2xl font-semibold'>Please select a question type first:</h1>
                        <select
                            onChange={(e) => setType(e.target.value)}
                            className="p-2 my-4 uppercase text-xs border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                            required
                            value={type}
                        >
                            <option className="text-xs uppercase" value=''>Select type</option>
                            <option className="text-xs uppercase" value='mcq'>MCQ</option>
                            <option className="text-xs uppercase" value='coding'>CODING</option>
                        </select>
                        <button className='bg-cyan-500 text-gray-200 hover:text-white hover:bg-cyan-700 px-5 py-2 rounded-md'
                            type='button'
                            onClick={handleProceed}
                        >Proceed
                        </button>

                        {errorMessage && <Alert className='text-sm my-3' color="failure" icon={HiInformationCircle}>{errorMessage}</Alert>}
                    </div>
                </div> : isTypeSelected && isSaved ?

                    // if Saved the Question
                    <div className='flex flex-col justify-center items-center'>
                        <h1 className='px-5 py-5 my-10 bg-green-500 text-white font-semibold'>Question has been saved successfully.</h1>
                        <button
                            type="button"
                            className=" bg-blue-600 text-white px-5 py-2 rounded hover:bg-blue-700"
                            onClick={handleAddMoreQuestion}
                        >Add more
                        </button>
                    </div> :

                    // if Type selected and question still not saved
                    <div className='w-full flex flex-col'>
                        <h1 className='text-center font-3xl font-bold'>Create a <span className='uppercase'>{type}</span> question:</h1>
                        {/* ---------- MCQ Question form ------------------- */}
                        {type !== "" && type === 'mcq' &&
                            <form onSubmit={handleMcqSubmit} className="w-full p-4 mx-auto border rounded shadow">

                                <div className='w-full flex justify-center gap-3'>
                                    {/* Skill Dropdown */}
                                    <div className="mb-4 w-1/2">
                                        <label htmlFor="language" className="block font-semibold mb-1">Skill</label>
                                        <select
                                            id="language"
                                            name="language"
                                            value={formData.language}
                                            onChange={handleSelectSkill}
                                            className="w-full p-2 border rounded"
                                            required
                                        >
                                            <option value="" disabled>Select a skill</option>
                                            {skillData.map((s, i) => <option key={i} value={s.value}>{s.name}</option>)}
                                        </select>
                                    </div>

                                    {/* Subject Dropdown */}
                                    <div className="mb-4 w-1/2">
                                        <label htmlFor="subject" className="block font-semibold mb-1">Subject</label>
                                        <select
                                            id="subject"
                                            name="subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                            className="w-full p-2 border rounded"
                                            required
                                        >
                                            <option value="" disabled>Select a subject</option>
                                            {selectedSkill && selectedSkill?.subjects.map((s, i) => <option value={s}>{s}</option>)}
                                        </select>
                                    </div>
                                </div>

                                {/* Question Field */}
                                <div className="mb-4 w-full ">
                                    <label htmlFor="question" className="block font-semibold mb-1">Question</label>
                                    <textarea
                                        id="question"
                                        name="question"
                                        value={formData.question}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded"
                                        placeholder="Enter the question"
                                        required
                                    />
                                </div>

                                {/* Options */}
                                <div className="mb-4">
                                    <label className="block font-semibold mb-2">Options</label>
                                    {formData.options.map((option, index) => (
                                        <div key={index} className="flex items-center mb-2">
                                            <span className="w-6 font-semibold">{String.fromCharCode(65 + index)}:</span>
                                            <input
                                                type="text"
                                                value={option}
                                                onChange={(e) => handleOptionChange(index, e.target.value)}
                                                className="w-full p-2 border rounded ml-2"
                                                placeholder={`Option ${index + 1}`}
                                                required
                                            />
                                        </div>
                                    ))}
                                </div>

                                {/* Answer Field */}
                                <div className="mb-4">
                                    <label htmlFor="answer" className="block font-semibold mb-1">Answer</label>
                                    <input
                                        id="answer"
                                        name="answer"
                                        value={formData.answer}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded"
                                        placeholder="Enter the correct answer"
                                        required
                                    />
                                </div>

                                {errorMessage && <Alert className='text-sm my-3' color="failure" icon={HiInformationCircle}>{errorMessage}</Alert>}

                                {/* Submit Button */}
                                <button
                                    type="submit"
                                    className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700"
                                    disabled={loading}
                                >
                                    {loading ? (<span><Spinner size="sm" />Submitting</span>) : 'Submit'}
                                </button>
                            </form>
                        }

                        {/* ------------ CODING Question Form ------------------ */}
                        {type !== "" && type === "coding" &&
                            <form onSubmit={handleCodingQuestion} className="w-full p-4 mx-auto border rounded shadow">
                                {/* Question Field */}
                                <div className="mb-4">
                                    <label htmlFor="question" className="block font-semibold mb-1">Question</label>
                                    <textarea
                                        id="question"
                                        name="question"
                                        value={codingFormData.question}
                                        onChange={handleCodingFormChange}
                                        className="w-full p-2 border rounded"
                                        placeholder="Enter the question"
                                        required
                                    />
                                </div>

                                {/* Instructions Field */}
                                <div className="mb-4">
                                    <label htmlFor="instructions" className="block font-semibold mb-1">Instructions</label>
                                    <textarea
                                        id="instructions"
                                        name="instructions"
                                        value={codingFormData.instructions}
                                        onChange={handleCodingFormChange}
                                        className="w-full p-2 border rounded"
                                        placeholder="Enter any instructions for the question"
                                    />
                                </div>

                                <div className='w-full flex justify-center gap-3'>
                                    {/* language Dropdown */}
                                    <div className="mb-4 w-1/2">
                                        <label htmlFor="language" className="block font-semibold mb-1">Skill</label>
                                        <select
                                            id="language"
                                            name="language"
                                            value={codingFormData.language}
                                            onChange={handleCodingFormChange}
                                            className="w-full p-2 border rounded"
                                            required
                                        >
                                            <option value="" disabled>Select a skill</option>
                                            {skillData
                                                .filter(skill => skill.type.includes("coding"))
                                                .map((skill, index) => (
                                                    <option key={index} value={skill.value}>
                                                        {skill.name}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    {/* Select Level */}
                                    <div className="mb-4 w-1/2">
                                        <label htmlFor="level" className="block font-semibold mb-1">Select Hardness:</label>
                                        <select
                                            id="level"
                                            name="level"
                                            value={codingFormData.level}
                                            onChange={handleCodingFormChange}
                                            className="w-full p-2 border rounded"
                                            required
                                        >
                                            <option value="" disabled>Select a level</option>
                                            <option value="easy">Easy</option>
                                            <option value="medium">Medium</option>
                                            <option value="hard">Hard</option>
                                        </select>
                                    </div>
                                </div>

                                {/* Predefine Code Field */}
                                <div className="mb-4">
                                    <label htmlFor="predefine" className="block font-semibold mb-1">Predefine Code</label>
                                    <textarea
                                        id="predefine"
                                        name="predefine"
                                        value={formData.predefine}
                                        onChange={handleCodingFormChange}
                                        className="w-full p-2 rounded h-32 bg-gray-900 border-2 border-gray-600 text-gray-500"
                                        placeholder="Enter the predefined code"
                                    />
                                </div>


                                {/* Answer Field */}
                                <div className="mb-4">
                                    <label htmlFor="answer" className="block font-semibold mb-1">Expected Output:</label>
                                    <input
                                        type="text"
                                        id="answer"
                                        name="answer"
                                        value={codingFormData.answer}
                                        onChange={handleCodingFormChange}
                                        className="w-full p-2 border rounded"
                                        placeholder="Enter the correct answer"
                                        required
                                    />
                                </div>

                                {errorMessage && <Alert className='text-sm my-3' color="failure" icon={HiInformationCircle}>{errorMessage}</Alert>}

                                <button
                                    type="submit"
                                    className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700"
                                    disabled={loading}
                                >
                                    {loading ? (<span><Spinner size="sm" />Submitting</span>) : 'Submit'}
                                </button>
                            </form>
                        }
                    </div>}
            </div>
        </div>
    )
}

export default AddQuestionModal