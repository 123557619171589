import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavbarVisibility } from '../components/NavbarVisibilityContext';
import { useFooterVisibility } from '../components/FooterVisibilityContext';

function Page404() {

    const { setIsNavbarVisible } = useNavbarVisibility();
    const { setIsFooterVisible } = useFooterVisibility();

    useEffect(() => {
        setIsFooterVisible(false);
        setIsNavbarVisible(false);
    }, [])

    return (
        <div className='w-full h-screen'>
            <div className='w-full max-w-[1140px] mx-auto flex flex-col items-center'>
                <img className='h-96' src="./images/404.webp" alt="404-img" />
                <h2 className='text-2xl uppercase font-semibold text-zinc-800'>page not found</h2>
                <p className='text-zinc-800 text-sm'>The requested URL was not found on this server.</p>
                <Link to='/' className='font-semibold text-xs text-zinc-800 underline my-4'>
                    Go back to home page
                </Link>
            </div>
        </div>
    )
}

export default Page404;